import { Router } from '@reach/router';
import Layout from 'components/Layout';
import React from 'react';
import { ReactQueryConfigProvider } from 'react-query';
import TransferDetails from 'wms/returns/details';
import Transfer from 'wms/returns/returns';

export default function TransferContainer(props) {
  return (
    <ReactQueryConfigProvider
      config={{ suspense: false, throwOnError: false, useErrorBoundary: false }}
    >
      <Layout {...props}>
        <div className="bg-background h-full">
          <Router>
            <Transfer path="/wms/returns" />
            <TransferDetails path="/wms/returns/details/:sessionId/:sessionCode/:sessionState" />
          </Router>
        </div>
      </Layout>
    </ReactQueryConfigProvider>
  );
}
