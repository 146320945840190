import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import LinearProgress from '@material-ui/core/LinearProgress';
import PersonAddTwoToneIcon from '@material-ui/icons/PersonAddTwoTone';
import {
  useCompleteReturnTask,
  useReturnsAssignWorker,
  useReturnsTasksDetails,
  useUpdateReturnTask,
  useWMSWorkers,
} from 'apiHooks/wmsHooks';
import React, { useEffect, useState } from 'react';
import { Modal } from 'rsuite';
import { useBranchId } from 'utils/customHooks';
import { codeNameContains } from 'utils/utils';
import { Action, Employee, HandlingUnit, Issue, SkuDetails } from 'wms/ira/IraCustomCells';
import { AssignedWorkers, AssignWorkerDialogContent, ConfirmationDialog } from 'wms/ira/IraDetails';
import IraDetailsTable from 'wms/ira/IraDetailsTable';
import IraIssuesContent from 'wms/ira/IraIssuesContent';
import StatusFilter from 'wms/ira/StatusFilter';
import TableFilters from 'wms/ira/tableFilters';

export default function TransferDetails(props) {
  const { sessionId, sessionCode, sessionState } = props;
  const branchId = useBranchId();

  const [modalState, setModalState] = useState({
    assignWorkerDialogVisibility: false,
    mismatchDialog: false,
    confirmDialogVisibility: false,
  });

  const [selectedWorkers, setSelectedWorkers] = useState({});
  const [assignedWorkerIds, setAssignedWorkerIds] = useState({});
  const [selected, setSelected] = useState();
  const [skuFilter, setSkuFilter] = useState('');
  const [employeeFilter, setEmployeeFilter] = useState('');

  const skuEmployeeFilter = (t) => {
    return codeNameContains(t.sku, skuFilter) && codeNameContains(t.worker, employeeFilter);
  };

  const args = { branchId, sessionId };
  const { data: workers, isFetching: isFetchingWorkers } = useWMSWorkers({ branchId });
  const [assignWorker, { status }] = useReturnsAssignWorker({
    ...args,
    onSuccess: () => setModalState((state) => ({ ...state, assignWorkerDialogVisibility: false })),
  });
  const { data: transferData, isFetching: isFetchingTransfer } = useReturnsTasksDetails(args);
  const [updateMismatch, { status: updateMismatchStatus }] = useUpdateReturnTask({
    ...args,
    onSuccess: () => setModalState((state) => ({ ...state, mismatchDialog: false })),
  });
  const [completeTransferSession, { status: completeSessionStatus }] = useCompleteReturnTask(args);

  const assignWorkerConfirmed = () => {
    const workerIds = workers?.filter((w) => !!selectedWorkers[w.id]).map((w) => w.id);
    assignWorker({ workerIds });
  };
  const assignedWorkerList = transferData?.workers?.filter((w) => !!assignedWorkerIds[w.id]);

  function getStatus(item) {
    return item.shortage ? (item.supervisorApproved ? 'Approved' : 'Pending') : 'N/A';
  }
  const filteredDetails = transferData?.transfers
    ?.map((t) => ({
      ...t,
      worker: transferData?.workers[0],
      status: getStatus(t),
      issue: t.shortage ? 'QTY' : '',
    }))
    .map((t) => ({
      ...t,
      action: t.shortage ? t : null,
    }))
    ?.filter(skuEmployeeFilter);

  // Columns for table
  const columns = [
    {
      Header: 'HU Code',
      accessor: (d) => d.hu,
      Cell: ({ cell: { value } }) => <HandlingUnit values={value} />,
    },
    {
      Header: 'SKU Details',
      accessor: (d) => d.sku,
      Cell: ({ cell: { value } }) => <SkuDetails values={value} />,
    },
    // {
    //   Header: 'Transfer TimeStamp',
    //   accessor: (d) => d.ira.timestamp,
    //   Cell: ({ cell: { value } }) => <IraSession values={value} />,
    // },
    {
      Header: 'Employee',
      accessor: (d) => d.worker,
      Cell: ({ cell: { value } }) => <Employee values={value} />,
    },
    {
      Header: 'Issue',
      accessor: 'issue',
      Cell: ({ cell: { value } }) => <Issue values={value} />,
    },
    {
      Header: 'Status',
      accessor: 'status',
      Filter: StatusFilter,
      filter: 'includes',
    },
    {
      Header: 'Action',
      accessor: 'action',
      Cell: ({ cell: { value } }) => {
        return value?.supervisorApproved ? (
          <span>Locked</span>
        ) : value?.issue && value?.issue !== '' ? (
          <Action
            state={sessionState}
            onClicked={() => {
              setSelected(value);
              setModalState((state) => ({ ...state, mismatchDialog: true }));
            }}
          />
        ) : (
          <span>N/A</span>
        );
      },
    },
  ];

  useEffect(() => {
    const workersReducer = (a, w) => ({ ...a, [w.id]: true });
    const assignedWorkersIds = transferData?.workers?.reduce(workersReducer, {}) || {};
    setAssignedWorkerIds(assignedWorkersIds);
    return () => {};
  }, [sessionId, transferData]);

  return (
    <div className="container bg-background w-screen h-screen">
      {isFetchingWorkers || isFetchingTransfer || completeSessionStatus === 'loading' ? (
        <LinearProgress
          classes={{
            colorPrimary: 'bg-green-300',
          }}
        />
      ) : null}

      <div className="flex-1 pt-8">
        <p className="text-base md:text-2xl antialiased sm:subpixel-antialiased md:antialiased font-semibold md:font-semibold mx-5">
          {transferData?.code}
        </p>
        <p className="text-sm md:text-sm text-tertiary mx-5">
          {transferData?.attrs?.returnMMTripId}
        </p>
      </div>

      {/* Content Section */}
      <div className="flex flex-col p-5 pt-0">
        {/* Section 1 containing info about the assigned workers */}
        <AssignedWorkers workerList={assignedWorkerList} />

        {/* Section 2 containing table */}
        <div className="flex flex-col rounded md:rounded-lg shadow md:shadow-lg bg-white p-5 mt-5">
          {/* header section containing count of approvals, search, filters etc */}
          <TableFilters
            pending={
              transferData?.transfers?.filter((item) => item.shortage && !item.supervisorApproved)
                ?.length
            }
            skuFilter={skuFilter}
            setSkuFilter={setSkuFilter}
            employeeFilter={employeeFilter}
            setEmployeeFilter={setEmployeeFilter}
          />

          {/* table section or content section */}
          <div className="flex flex-col">
            <IraDetailsTable columns={columns} data={filteredDetails || []} />
          </div>
        </div>

        {/* Section 3 complete button */}
        <div className="w-screen fixed bottom-3 inset-x-1/2 justify-center items-center">
          <Button
            disabled={sessionState === 'COMPLETED' ? true : false}
            variant="contained"
            color="primary"
            onClick={() => setModalState((p) => ({ ...p, confirmDialogVisibility: true }))}
          >
            {sessionState === 'COMPLETED' ? 'Completed' : `Complete Tranfer for ${sessionCode}`}
          </Button>
        </div>

        {/*FAB Section */}
        <div className="fixed bottom-5 right-5">
          <Fab
            disabled={sessionState === 'COMPLETED' ? true : false}
            size="medium"
            color="primary"
            aria-label="assign_worker"
            onClick={() => setModalState((p) => ({ ...p, assignWorkerDialogVisibility: true }))}
          >
            <PersonAddTwoToneIcon />
          </Fab>
        </div>
      </div>

      {/* Assign Worker dialog */}
      <Modal
        size="sm"
        overflow={true}
        show={modalState.assignWorkerDialogVisibility}
        onHide={() => setModalState((p) => ({ ...p, assignWorkerDialogVisibility: false }))}
      >
        <Modal.Body>
          <AssignWorkerDialogContent
            label="Transfer"
            loading={status === 'loading' ? true : false}
            workerList={workers?.map((w) => ({
              ...w,
              isChecked: !!selectedWorkers[w.id] || !!assignedWorkerIds[w.id],
            }))}
            onCheckboxClicked={(id) => setSelectedWorkers((p) => ({ ...p, [id]: !p[id] }))}
            onAssignWorkerConfirmed={assignWorkerConfirmed}
            onCancel={() => setModalState((p) => ({ ...p, assignWorkerDialogVisibility: false }))}
          />
        </Modal.Body>
      </Modal>

      {/* Ira Issue Content dialog */}
      <Modal
        size="sm"
        overflow={true}
        show={modalState.mismatchDialog}
        onHide={() => setModalState((p) => ({ ...p, mismatchDialog: false }))}
      >
        <Modal.Body>
          <IraIssuesContent
            data={{ ...(selected || {}), approved: { qtyL0: selected?.qtyL0 } }}
            hideSystem={true}
            hideDamaged={true}
            label="Transfer"
            onClose={() => setModalState((p) => ({ ...p, mismatchDialog: false }))}
            status={updateMismatchStatus === 'loading' ? true : false}
            onApproveClicked={(id, damagedQty, quantity) =>
              updateMismatch({ updateId: id, damagedQty: damagedQty, quantity: quantity })
            }
          />
        </Modal.Body>
      </Modal>

      {/* Confirmation dialog to complete the session */}
      <ConfirmationDialog
        title={'Attempting to complete the Transfer session'}
        hide={() => setModalState((p) => ({ ...p, confirmDialogVisibility: false }))}
        confirmDialogVisibility={modalState.confirmDialogVisibility}
        completeSession={completeTransferSession}
      />
    </div>
  );
}
