import LinearProgress from '@material-ui/core/LinearProgress';
import Pagination from '@material-ui/lab/Pagination';
import { useWMSReturnsTasks } from 'apiHooks/wmsHooks';
import Link from 'components/Link';
import React, { useMemo, useState } from 'react';
import { useBranchId } from 'utils/customHooks';
import { formatDateTime, getDateRange } from 'utils/utils';
import WmsFilters from 'wms/WmsFilters';

const initialDates = getDateRange(new Date(), 7);

export default function Transfer() {
  const branchId = useBranchId();
  const [startDate, setStartDate] = useState(initialDates[0]);
  const [endDate, setEndDate] = useState(initialDates[1]);
  const [activePage, setActivePage] = useState(1);
  const offset = useMemo(() => (activePage - 1) * 12, [activePage]);

  const handlePageChange = (e, value) => setActivePage(value);

  function onDateFilterChanged(dates) {
    const newDates = dates.length ? dates : initialDates;

    setStartDate(newDates[0]);
    setEndDate(newDates[1]);
  }
  const { data, isFetching } = useWMSReturnsTasks({ branchId, startDate, endDate });
  const sessionList = data || [];

  return (
    <div>
      {isFetching && (
        <LinearProgress
          classes={{
            colorPrimary: 'bg-green-300',
          }}
        />
      )}

      <div className="flex flex-col w-full h-full">
        {/* Header Section containing Filters */}
        <div className="flex flex-row m-5 justify-center items-center">
          <div className="flex-1">
            <p className="text-base md:text-2xl antialiased sm:subpixel-antialiased md:antialiased font-semibold md:font-semibold mx-5">
              Returns tasks List
            </p>
          </div>
          <WmsFilters
            onFiltersChange={onDateFilterChanged}
            onFilterCancelled={() => onDateFilterChanged([])}
          />
        </div>
        {/* Session List Section */}
        <div className="grid grid-cols-1 md:grid-cols-4">
          {sessionList?.slice(offset, offset + 12).map((item) => (
            <SessionItem key={item?.id} session={item} />
          ))}
        </div>
        {/* Pagination section */}
        <div className="mt-5 m-auto">
          {sessionList?.length / 12 > 1 && (
            <Pagination onChange={handlePageChange} count={Math.ceil(sessionList?.length / 12)} />
          )}
        </div>
      </div>
    </div>
  );
}

function SessionItem(props) {
  const { session } = props;

  return (
    <div className="rounded md:rounded-lg shadow md:shadow-lg bg-white m-5 p-3">
      <Link to={`/wms/returns/details/${session?.id}/${session?.code}/${session?.state}`}>
        <div className="flex-2 flex-col flex-grow">
          <p className="text-base md:text-base font-bold md:font-bold m-1">{session?.code}</p>
          <div className="flex flex-col">
            <p className="text-xs md:text-xs font-medium md:font-medium m-1">
              {formatDateTime(new Date(session?.createdAt))}
            </p>
            <p className="text-xs md:text-xs font-medium md:font-medium m-1">
              <span className="text-xs font-medium">Return MM Trip : </span>
              {session?.attrs?.returnMMTripId || '--'}
            </p>
            <p className="text-xs md:text-xs font-medium md:font-medium m-1">{session?.state}</p>
          </div>
        </div>
      </Link>
    </div>
  );
}
